import { Link, Head } from '@inertiajs/react';
import { PageProps } from '@/types';
import './Welcome.css';
import { MovingBorderBtn } from '@/Components/Custom/MovingBorderBtn';
import { Button } from '@/Components/ui/button';
import { BackgroundBeams } from '@/Components/Custom/BackgroundBeams';
import NavLink from '@/Components/NavLink';
import ApplicationLogo from '@/Components/ApplicationLogo';

export default function Welcome({ auth, laravelVersion, phpVersion }: PageProps<{ laravelVersion: string, phpVersion: string }>) {
    const btn = document.getElementById('menu-btn')
    const nav = document.getElementById('menu')

    btn?.addEventListener('click', () => {
        btn.classList.toggle('open')
        nav?.classList.toggle('flex')
        nav?.classList.toggle('hidden')
    })

    return (
        <>
            <div className="">

                <Head title="Welcome" />
                {/* add style */}
                <nav className="relative container mx-auto px-4 py-2 mt-4 text-white bg-neutral-950 rounded-full">
                    <div className="flex items-center justify-between">
                        <div className="pt-2 font-bold text-[#7e8bff]">
                            <Link href="/">
                                {"Jewels Of Kindness"}
                            </Link>
                        </div>
                        <div className="hidden space-x-6 md:flex">
                            <a href="#about" className="hover:text-darkGrayishBlue">About Us</a>
                            {auth.user ? (
                                //<NavLink className='hover:no-underline' href={route('scholarship.request')} active={route().current('scholarship.request')}>
                                //    {"Apply for Scholarships"}
                                //</NavLink>
                                <Link href={route('scholarship.request')} className="hover:text-darkGrayishBlue">
                                    {"Apply for Scholarships"}
                                </Link>
                            ) : (
                                <>
                                    <Link href="/login" className="hover:text-darkGrayishBlue">
                                        Login
                                    </Link>
                                    <Link href="/register" className="hover:text-darkGrayishBlue">
                                        Register
                                    </Link>
                                </>
                            )}

                        </div>
                        <Link href={route('scholarship.request')} className="hover:text-darkGrayishBlue mr-3">
                            <MovingBorderBtn
                                borderRadius="1.75rem"
                                className="bg-[#18153a] text-white border-slate-800"
                            >
                                Apply For Scholarship

                            </MovingBorderBtn>
                        </Link>
                        <button
                            id="menu-btn"
                            className="block hamburger md:hidden focus:outline-none"
                        >
                            <span className="hamburger-top"></span>
                            <span className="hamburger-middle"></span>
                            <span className="hamburger-bottom"></span>
                        </button>
                    </div>

                    <div className="md:hidden">
                        <div
                            id="menu"
                            className="absolute flex-col items-center hidden self-end py-8 mt-10 space-y-6 font-bold bg-white sm:w-auto sm:self-center left-6 right-6 drop-shadow-md"
                        >
                            <a href="#about">About Us</a>
                            <Link href="/login" className="hover:text-darkGrayishBlue">
                                Login
                            </Link>
                            <Link href="/register" className="hover:text-darkGrayishBlue">
                                Register
                            </Link>
                        </div>
                    </div>
                </nav>

                <section id="hero" className='text-back'>
                    <div
                        className="container flex w-100 flex-col-reverse items-center px-6 mx-auto space-y-0 md:space-y-0 md:flex-row"
                    >
                        <div className="flex flex-col mb-24 space-y-12 md:w-1/2">

                            <h1 className="text-3xl font-black text-gray-900 mt-12 dark:text-white md:text-5xl lg:text-6xl">
                                <span className="text-transparent bg-clip-text bg-gradient-to-r to-emerald-600 from-[#262261] ">
                                    {"Welcome to "}
                                </span>
                                {" Jewels of Kindness Foundation."}
                            </h1>
                            <p className="text-lg font-normal text-gray-500 lg:text-xl dark:text-gray-400">Here at Jewels of Kindness Foundation we Rebuild the human dignity of the under-privileged and marginalized.</p>

                            <div className="flex justify-center md:justify-start">
                                <Link href={route('scholarship.request')} className="hover:text-darkGrayishBlue">
                                    <Button
                                        className="bg-[#18153a] text-white border-slate-800 rounded-3xl"
                                    >
                                        Apply For Scholarship

                                    </Button>
                                </Link>
                            </div>
                        </div>
                        {/*<BackgroundBeams />*/}
                        <div className="md:w-1/2">
                            <img src="img/wellness.png" alt="" />
                        </div>

                    </div>
                </section>

                <section id="about">
                    <div className="h-[40rem] w-full rounded-md bg-neutral-950 relative flex flex-col items-center justify-center antialiased">
                        <div className="max-w-2xl mx-auto p-4">
                            <h1 className="relative z-10 text-5xl mb-5 md:text-7xl  bg-clip-text text-transparent bg-gradient-to-b from-neutral-200 to-neutral-600  text-center font-sans font-bold">
                                About Us
                            </h1>
                            <p></p>
                            <p className="text-neutral-400 mx-auto my-2 text-lg text-center relative z-10">
                                “Jewels of Kindness Foundation aims to rebuild the human dignity of the under-privileged and marginalized through an empowerment process, Education, Health Care, Employment and Self-employment assistance to unemployed and create opportunities for a sustainable society. The foundation strives for Social Change and Inclusion works for the social development and integration of underprivileged individuals, groups and communities. The foundation intends to break the vicious cycle of poverty and social imbalance and to restore hope for a better future. We believe that every person has the right to access resources and opportunities in order to live and develop with dignity and to become an active and contributing member of our society.”
                            </p>
                        </div>
                        <BackgroundBeams />
                    </div>

                </section>


                <footer className="bg-veryDarkBlue">
                    <div
                        className="container flex flex-col-reverse justify-between px-6 lg:py-5 mx-auto space-y-8 md:flex-row md:space-y-0"
                    >
                        <div
                            className="flex flex-col-reverse items-center justify-between space-y-1 md:flex-col md:space-y-0 md:items-start"
                        >
                            <div className="mx-auto my-2 text-center text-white md:hidden">
                                Copyright &copy; 2024, All Rights Reserved
                            </div>
                            <div className='text-white'>
                                {"Jewels of Kindness Foundation"}
                            </div>
                        </div>
                        <div className="flex justify-around space-x-32">
                            <div className="flex flex-col space-y-1 text-white">
                                <a href="#about" className="hover:text-brightRed">About</a>
                            </div>
                        </div>

                        <div className="flex flex-col justify-between">
                            <div className="hidden text-white md:block">
                                Copyright &copy; 2024, All Rights Reserved
                            </div>
                        </div>
                    </div>
                </footer>
            </div>

        </>
    );
}
